<template>
  <div class="content_area wrap">
    <div class="step">
      <div>
        1.查询条件输入
      </div>
      <div :class="{'active':step === 3}">
        2.结果查询
      </div>
    </div>
    <template v-if="step === 1">
      <div class="tab">
        <span class="cursor" @click="tabClick(1)" :class="{'active':active === 1}">个人</span>
        <span class="cursor" @click="tabClick(2)" :class="{'active':active === 2}">企业</span>
      </div>
      <div class="form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="证书名称：" prop="certificate" v-if="active === 1">
            <el-select
                style="width: 100%"
                v-model="ruleForm.certificate"
                placeholder="请选择证书名称"
            >
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :value="item.id"
                  :label="item.certificate_name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名：" prop="name" v-if="active === 1">
            <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <!--<el-form-item label="证书号：" prop="number" v-if="active === 1">-->
          <!--  <el-input v-model="ruleForm.number" placeholder="请输入证书号"></el-input>-->
          <!--</el-form-item>-->
          <el-form-item label="企业名称：" prop="companyName">
            <el-input v-model="ruleForm.companyName" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" type="primary" block @click="resetForm('ruleForm')">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-if="step === 2">
      <div class="back">
        <i @click="back" class="el-icon-arrow-left cursor"></i>
        <span @click="back" class="cursor">返回</span>
      </div>
      <div class="info">
        <el-table :data="list" :height="550" style="width: 100%" border>
          <el-table-column label="企业名称" :show-overflow-tooltip="true">
            <template slot-scope="{ row }">{{row.company_name}}</template>
          </el-table-column>
          <el-table-column label="姓名" :show-overflow-tooltip="true">
            <template slot-scope="{ row }">{{row.name}}</template>
          </el-table-column>
          <el-table-column label="证书名称" :show-overflow-tooltip="true">
            <template slot-scope="{ row }">{{row.dga_certificate_name}}</template>
          </el-table-column>
          <el-table-column label="操作" :show-overflow-tooltip="true">
            <template slot-scope="{ row }" width="150">
              <el-button type="text" @click="viewCard(row)">查看证书</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template v-if="step === 3">
      <template v-if="list.length">
        <div class="back">
          <i @click="back" class="el-icon-arrow-left cursor"></i>
          <span @click="back" class="cursor">返回</span>
        </div>
        <div class="content">
          <div class="left">
            <div class="item">
              <div class="key">
                证书名称
              </div>
              <div class="value">
                {{ certificateDetail.dga_certificate_name|textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="key">
                姓名
              </div>
              <div class="value">
                {{ certificateDetail.name|textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="key">
                证书号
              </div>
              <div class="value">
                {{ certificateDetail.certificate_no }}
              </div>
            </div>
            <div class="item">
              <div class="key">
                企业名称
              </div>
              <div class="value">
                {{ certificateDetail.company_name|textFormat }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="certificate">
              <!-- <el-carousel
                  height="482px"
                  indicator-position="none"
                  :arrow="list.length>1?'always':'never'"
                  @change="changeImg"
                  direction="horizontal"
              >
                <el-carousel-item class="carouselItem" v-for="(item,index) in list" :key="index"> -->
                  <el-image
                      style="width: 342px; height: 482px"
                      :src="certificateDetail.certificate_url"
                      fit="scale-down"
                      @dragstart.prevent
                      @contextmenu.prevent
                  >
                  </el-image>

                <!-- </el-carousel-item>
              </el-carousel> -->
            </div>
            <div class="img-wrap">
              <div class="cursor" @click="onPreview">
                <img :src="enlarge" alt="">
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="nodata">
          <noDataImg class="nodata-img"/>
          <el-button @click="back" class="btn" type="primary" plain>返回查询</el-button>
        </div>
      </template>
    </template>
    <el-image
        ref="preview"
        class="previewItem"
        src=""
        fit="scale-down"
        :preview-src-list="previewArr"
    >
    </el-image>
  </div>
</template>

<script>
export default {
  name: "index",
  metaInfo: {
    title: "证书查询",
  },
  data() {
    return {
      certificateDetail: {},
      active: 1, // 1个人 2企业
      step: 1, // 1信息填写  2列表  3信息
      list: [],
      ruleForm: {
        certificate: null,
        name: null,
        // number: null,
        companyName: null
      },
      rules: {
        certificate: {
          required: true,
          message: '请选择证书名称',
          trigger: 'change'
        },
        name: {
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        },
        // number: {
        //   required: true,
        //   message: '请输入证书号',
        //   trigger: 'blur'
        // },
        companyName: {
          required: true,
          message: '请输入企业名称',
          trigger: 'blur'
        },
      },
      options: [],
      enlarge: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/enlarge.png',
      imgIndex:null,//当前第几张图片 无数据第一张
      previewArr:[]

    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    viewCard(row) {
      this.certificateDetail = row
      this.step = 3
    },
    async getList() {
      let params = {}
      params.nopage = 1
      let res = await this.$store.dispatch("API_certificate/certificateName", params);
      if (res.success) {
        this.options = res.data
      }
    },
    tabClick(value) {
      this.active = value
      this.$refs.ruleForm.resetFields()
    },
    resetForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.resetFormFn()
          this.$refs[formName].resetFields();
        } else {
          return false;
        }
      });
    },
    async resetFormFn() {
      let params = {}
      params.company_name = this.ruleForm.companyName
      params.certificate_name = this.ruleForm.certificate
      if (this.active === 1) {
        params.name = this.ruleForm.name
        // params.certificate_no = this.ruleForm.number
      }
      let res;
      if (this.active === 1) {
        res = await this.$store.dispatch('API_certificate/searchUserCertificate', params)
      } else {
        res = await this.$store.dispatch('API_certificate/searchCompanyCertificate', params)
      }
      if (res.success) {
        if(this.active==1) {
          if(res.data.length>0) {
            this.list = res.data
            this.step = 2
          }else {
            this.certificateDetail = res.data[0]
            this.step = 3
          }
        }else if(this.active==2) {
          this.list = res.data
          this.step = 2
        }
      }
    },
    preview() {

    },
    back() {
      if(this.active==2&&this.step==2){
        this.step = 1
        return
      } 
      if(this.active==2){
       return this.step = 2
        
      }
      if(this.active==1&&this.step==3&&this.list.length>1) {
        return this.step = 2
      }
      this.step = 1
      this.list = null
    },
    changeImg(val) {
      this.imgIndex=val
    },
    onPreview(){
      let item= this.imgIndex?this.list[this.imgIndex]:this.list[0]
      this.previewArr=[]
      this.previewArr.push(this.certificateDetail.certificate_url)
      this.$nextTick(()=>{
        this.$refs.preview.clickHandler()
        this.$nextTick(()=>{
          let img=document.querySelector('.el-image-viewer__img')
          img.oncontextmenu=function(){
            return false
          }
          img.contextmenu=function(){
            return false
          }

        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.cursor {
  cursor: pointer;
}

.wrap {
  margin-top: 22px;
  background-color: #fff;
  height: 783px;
  padding: 60px 92px;
  position: relative;

  .step {
    width: 100%;
    background: #F5F7FA;
    height: 59px;
    line-height: 59px;
    display: flex;
    position: relative;

    div:first-child {
      width: 544px;
      padding-left: 161px;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/certifityleft.png") no-repeat 100% 100%;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }

    div:last-child {
      width: 472px;
      padding-left: 189px;
      font-size: 14px;
      font-weight: 400;
      color: #6A6C71;
    }

    .active {
      position: absolute;
      width: 497px !important;
      right: 0;
      top: 0;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/certificateright.png") no-repeat 497px 59px;
      background-position: 0 0;
      z-index: 99;
      color: #FFFFFF !important;
    }
  }

  .tab {
    display: flex;
    justify-content: center;
    font-size: 21px;
    font-weight: 400;
    color: #707376;
    height: 38px;
    margin-top: 37px;
    margin-bottom: 37px;

    span:first-child {
      margin-right: 101px;
    }

    .active {
      position: relative;
      color: #3D3B3B;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        left: 0;
        bottom: 0;
        background: #116BFF;
        border-radius: 2px;
      }

    }
  }

  .form {
    width: 100%;
    padding: 0 280px;

    .btn {
      background-color: #116BFF;
      width: 100%;
    }
  }

  .back {
    margin-top: 22px;
  }

  .content {
    padding: 0 60px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 437px;

      .item {
        margin-bottom: 23px;

        .key {
          font-size: 16px;
          font-weight: 800;
          color: #27292D;
          margin-bottom: 12px;
        }

        .value {
          width: 100%;
          height: 50px;
          background: #F3F3F3;
          border-radius: 4px;
          padding-left: 20px;
          line-height: 50px;
          font-size: 16px;
          font-weight: 500;
          color: #797A7C;
        }
      }
    }

    .right {
      padding-top: 8px;
      position: relative;
      flex: 1;

      .certificate {
        width: 100%;
        ::v-deep .el-carousel {
          width: 100%;
          padding: 0 50px 0 60px;
          .el-carousel__container {
            .el-carousel__arrow {
              font-size: 25px;
              color: #C5C4C4;
              background-color: #fff;
              border: 1px solid #EDEDED;
            }

            .el-carousel__arrow--left {
              left: -50px;
            }

            .el-carousel__arrow--right {
              right: -45px;
            }
          }
        }


      }
      .img-wrap {
        position: absolute;
        right: 0;
        top: 8px;

        div {
          width: 22px;
          height: 22px;
          text-align: center;
          background: #D8D8D8;
          border-radius: 4px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .nodata {
    position: absolute;
    top: 300px;
    width: 100px;
    height: 200px;
    left: 50%;
    transform: translateX(-50%);

    .nodata-img {
      height: 125px;
    }

    .btn {
      position: absolute;
      top: 150px;
      color: #116BFF;
      border: 1px solid #116BFF;
      background-color: #fff;
    }

  }

  .info {
    margin-top: 10px;
  }

  ::v-deep .previewItem{
    .el-image__error{
      display: none;
    }
  }
}
</style>